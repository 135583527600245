import(/* webpackIgnore: true */ window.thComponentsUrl("/modules/tds-toaster.js")).then(({ showToast }) => {
  const DEFAULT_DURATION = 6000;

  const buildUnsafeMessage = (heading) => {
    const link = document.createElement('a');
    link.href = heading.href;
    link.text = heading.label;
    return link.outerHTML;
  }

  // support inconsistent detail interface
  const transformDetail = (detail) => {
    let message = typeof(detail.heading) === 'string' ? detail.heading : null;
    let unsafeMessage = typeof(detail.heading) === 'object' ? buildUnsafeMessage(detail.heading[0]) : null;
    let duration = typeof(detail.duration) === 'undefined' ? DEFAULT_DURATION : detail.duration;

    return {
      variant: detail.variant,
      unsafeMessage: message ? message : unsafeMessage,
      duration: duration
    }
  }

  const summonToast = (e) => {
    const toastOptions = transformDetail(e.detail);

    showToast(toastOptions);
  }

  window.addEventListener('showToast', summonToast);

  // needed to support flash[:], see _toaster.html.erb
  if(window.flashEvents) {
    window.flashEvents();
  }
});
